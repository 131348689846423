import React, {Component} from 'react';
import lsd from "../../lsd.jpg"

class home extends Component{
    render(){
    return (
    <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <img width = "400" src={lsd}></img>
        <br />
        <br />
    </div>
  );
}
}

export default home;